import React, { Component } from 'react';

class Bio extends Component {
    render() { 
        return (
            <div className="bandDescription">
                <hr className="divider rounded"/>
            <p>
                <span className="highlight">Drop Diver</span> is a Huntsville, AL-based garage/indie/alt-rock band formed in January 2019. 
            </p>
            <ul className="bandMembers">
                <li>Garrison Lemmond - Vocals and Rhythm Guitar</li>
                <li>Evan Ohlsen - Lead Guitar</li>
                <li>Dylan Coty - Bass</li>
                <li>Brennan Persing - Drums</li>
            </ul>
            <hr className="divider rounded"/>
            </div>
        );
    }
}
 
export default Bio;
