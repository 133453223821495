import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Spotify from '../components/spotify.js';
import YouTube from '../components/youtube.js';
import Bio from '../components/bio.js';
import Contact from '../components/contact.js';

const IndexPage = () => (
  <Layout>
    <div>
      <SEO title="Home" />
      <h1 className="title">DROP DIVER</h1>
      <h2 className="subtitle">NEW ALBUM MODEL CITIZENS <a target="_blank" rel="noopener noreferrer" className="outNow" href="https://www.youtube.com/watch?v=pvlwN6Yl-cI&list=OLAK5uy_mhgH2L_Cn-V_SKuTdq0qkqnYayXiAsDdY&index=1">OUT NOW</a></h2>
      <YouTube />
      <Spotify />
      <Bio />
      <Contact />
    </div>
  </Layout>
)

export default IndexPage
