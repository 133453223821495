import React, { Component } from 'react';

class YouTube extends Component {
    render() { 
        return ( 
            <div><iframe title="youtube" width="560" height="315" src="https://www.youtube.com/embed/RTe1425SAhY?controls=0" frameBorder="0" allowFullScreen></iframe></div>
        );
    }
}
 

export default YouTube;
