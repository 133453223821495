import React from 'react';

function Contact() {

    return (
        <div className="contact">
            <h1 className="title">CONTACT <i className="fas fa-envelope"></i></h1>
            <form name="contact" method="post" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <div className="form-group">
                    <input type="text" placeholder="NAME" name="name" className="form-control" />
                </div>
                <div className="form-group">
                    <input type="email" className="form-control" placeholder="EMAIL" name="email" />
                </div>
                <div className="form-group">
                    <textarea className="form-control" placeholder="MESSAGE" name="message" rows="5"></textarea>
                </div>
                <div className="form-group">
                    <div data-netlify-recaptcha="true"></div>
                </div>
                <button type="submit" className="button contact-button"><i className="fas fa-paper-plane"></i></button>
            </form>
        </div>
    )
}

export default Contact;