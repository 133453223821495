import React, { Component } from 'react';

class Spotify extends Component {
    render() { 
        return ( 
            <div><iframe title="spotify" src="https://open.spotify.com/embed/artist/6u2OjiKcH2rvXfszl4gVyj" width="560" height="400" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe></div>
        );
    }
}
 
export default Spotify;